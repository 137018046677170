import React from 'react';
import { animated } from 'react-spring';
import { useSpring } from '@react-spring/core';
import { useHistory } from 'react-router-dom';

const LandingScreen: React.FC = () => {
  const history = useHistory();
  const springValues = (highlighted: boolean) => {
    return {
      transform: `translateY(${highlighted ? -6 : 0}px)`,
      config: { mass: 1, tension: 300, friction: 15 },
    };
  };

  const [spring, setSpring] = useSpring(() => springValues(false));

  const openSteam = () => {
    window.open('https://store.steampowered.com/app/1345320', '_self');
  };

  const openImpressum = () => {
    history.push('/impressum');
  };

  return (
    <div
      className="relative flex flex-col items-center w-screen h-screen bg-black bg-cover bg-center overflow-hidden"
      style={{ backgroundImage: `url(images/Star-Background.png)` }}
    >
      {/* Title */}
      <div className="dsg-title flex flex-1 flex-col justify-center" style={{ width: '50rem', maxWidth: '92%' }}>
        <div
          className="w-full h-96 bg-contain bg-center bg-no-repeat"
          style={{ maxHeight: '30vh', backgroundImage: 'url(images/DSG-Title.png)' }}
        />
      </div>
      <div className="relative flex justify-center w-full" style={{ minWidth: '70rem' }}>
        {/* Spacegarden */}
        <img
          className="space-garden"
          src="images/Title-Spacegarden-Cut.png"
          style={{ width: '70rem', maxWidth: '200vw' }}
        />
        {/* Gradient overlay */}
        <div
          className="absolute w-full h-full"
          style={{ background: 'linear-gradient(rgba(8,9,20,0) 30%, rgba(8,9,20,1) 100%)' }}
        />
      </div>
      {/* Steam button */}
      <animated.div
        onMouseEnter={() => {
          setSpring(springValues(true));
        }}
        onMouseLeave={() => {
          setSpring(springValues(false));
        }}
        onClick={openSteam}
        className="absolute bottom-20 flex items-center px-9 py-3 rounded-2xl cursor-pointer space-x-4"
        style={{
          ...spring,
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
        }}
      >
        {/* Steam icon */}
        <svg
          className="w-12 h-12 text-white fill-current"
          viewBox="0 0 256 259"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid"
        >
          <path d="M127.779 0C60.42 0 5.24 52.412 0 119.014l68.724 28.674a35.812 35.812 0 0120.426-6.366c.682 0 1.356.019 2.02.056l30.566-44.71v-.626c0-26.903 21.69-48.796 48.353-48.796 26.662 0 48.352 21.893 48.352 48.796 0 26.902-21.69 48.804-48.352 48.804-.37 0-.73-.009-1.098-.018l-43.593 31.377c.028.582.046 1.163.046 1.735 0 20.204-16.283 36.636-36.294 36.636-17.566 0-32.263-12.658-35.584-29.412L4.41 164.654c15.223 54.313 64.673 94.132 123.369 94.132 70.818 0 128.221-57.938 128.221-129.393C256 57.93 198.597 0 127.779 0zM80.352 196.332l-15.749-6.568c2.787 5.867 7.621 10.775 14.033 13.47 13.857 5.83 29.836-.803 35.612-14.799a27.555 27.555 0 00.046-21.035c-2.768-6.79-7.999-12.086-14.706-14.909-6.67-2.795-13.811-2.694-20.085-.304l16.275 6.79c10.222 4.3 15.056 16.145 10.794 26.46-4.253 10.314-15.998 15.195-26.22 10.895zm121.957-100.29c0-17.925-14.457-32.52-32.217-32.52-17.769 0-32.226 14.595-32.226 32.52 0 17.926 14.457 32.512 32.226 32.512 17.76 0 32.217-14.586 32.217-32.512zm-56.37-.055c0-13.488 10.84-24.42 24.2-24.42 13.368 0 24.208 10.932 24.208 24.42 0 13.488-10.84 24.421-24.209 24.421-13.359 0-24.2-10.933-24.2-24.42z" />
        </svg>
        {/* Steam text */}
        <div className="py-3 text-white text-3xl font-semibold select-none">Wishlist on Steam</div>
      </animated.div>
      <div
        className="absolute right-0 top-0 p-3 text-white opacity-60 cursor-pointer transform origin-bottom-right -translate-y-8 uppercase -rotate-90"
        onClick={openImpressum}
      >
        Impressum
      </div>
    </div>
  );
};

export default LandingScreen;
