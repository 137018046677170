import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Impressum from './views/screens/Impressum/Impressum';
import LandingScreen from './views/screens/Landing/LandingScreen';

/** The App's root component */
const App: React.FC = () => {
  return (
    <div>
      <Switch>
        <Route path="/impressum" exact component={Impressum} />
        <Route path="/" component={LandingScreen} />
        {/* Redirect all other to landing */}
        <Redirect from="*" to="/" />
      </Switch>
    </div>
  );
};

export default App;
