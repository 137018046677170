import React from 'react';
import { useHistory } from 'react-router-dom';

const Impressum: React.FC = () => {
  const history = useHistory();

  const backAction = () => {
    history.push('/');
  };

  return (
    <div className="flex flex-col items-center justify-center w-screen min-h-screen select-none space-y-12">
      {/* Title */}
      <div
        onClick={backAction}
        className="dsg-title flex flex-col justify-center cursor-pointer"
        style={{ width: '50rem', maxWidth: '92%' }}
      >
        <div
          className="w-full h-96 bg-contain bg-center bg-no-repeat"
          style={{ maxHeight: '20vh', backgroundImage: 'url(images/DSG-Title.png)' }}
        />
      </div>
      <div className="flex flex-col space-y-4">
        <p className="text-white">Disclosures in accordance with § 5 TMG:</p>
        <p className="text-white">
          Nevolane GmbH
          <br />
          Heidenerstrasse 22a
          <br />
          9400 Rorschach
          <br />
          Switzerland
        </p>
        <p className="text-white">
          Contact
          <br />
          <p className="cursor-pointer" onClick={backAction}>
            www.deepspacegardening.com
          </p>
          <p className="cursor-pointer" onClick={() => window.open('mailto:contact@nevolane.com')}>
            contact@nevolane.com
          </p>
        </p>
        <p className="text-white">
          UID: CHE-431.395.920
          <br />
          Managing director: Nicholas Ingulfsen
        </p>
      </div>
    </div>
  );
};

export default Impressum;
